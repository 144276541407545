.modal-overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(0, 0, 0, 0.7);
  z-index: 1000;
}
.modal {
  width: 90%;
  height: 90vh;
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #eee;
  z-index: 1000;
  padding: 20px;
  overflow-y: scroll;
  overflow-x: hidden;
  margin: auto;
  border-radius: 5px;
}

.modal-container {
  display: flex;
  align-items: center;
}
.modal-image-container {
  width: 60%;
  height: auto;
  background-color: #fff;
  padding: 56px 70px;
}
.modal-image {
  width: 100%;
  min-height: 400px;
  background-color: #000;
  transition: 0.5s;
  border-radius: 5px;
  background-size: contain !important;
}
.modal-image:hover {
  box-shadow: 0px 0px 10px #000;
}
.modal-title {
  width: 40%;
  height: 300px;
  position: relative;
  left: -70px;
  background-color: #a60000dd;
  padding-left: 100px;
  display: flex;
  flex-direction: column;
  justify-content: center;
}
.modal h1 {
  color: #fff;
  margin-bottom: 40px;
  text-transform: uppercase;
}
.modal h4 {
  color: #fff;
  margin-top: 40px;
  margin-right: 30px;
  text-align: right;
}
.modal a {
  text-decoration: none;
  color: #000;
}
.modal p {
  max-width: 1024px;
  margin: auto;
  text-align: left;
  line-height: 1.6;
  font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 20px;
  margin-top: 20px;
}
.modal-close {
  position: sticky !important;
  top: 0;
  left: 100%;
  z-index: 10;
}
/* swiper carousel */
.swiper-button-prev::after,
.swiper-button-next::after {
  color: #fff;
  font-size: 30px;
  font-weight: 900;
}
.swiper-pagination-bullet {
  background-color: #fff;
}

@media screen and (max-width: 776px) {
  .modal {
    width: 100%;
    height: 100vh;
    padding: 0px;
  }
  .modal h1,
  .modal h4 {
    margin: 10px;
  }
  .modal p {
    padding: 10px;
    position: relative;
    top: -40px;
  }
  .modal-container {
    flex-direction: column;
    justify-content: center;
    position: relative;
    top: -40px;
  }
  .modal-close {
    position: sticky !important;
    top: 0;
    left: 100%;
    z-index: 10;
  }
  .modal-image {
    min-height: 350px;
  }
  .modal-image-container {
    padding: 0px;
    width: 100%;
  }
  .modal-title {
    top: -10px;
    left: 0;
    height: auto;
    width: 100%;
    padding: 20px 0px;
    text-align: center;
  }
  .swiper-button-prev::after,
  .swiper-button-next::after {
    display: none;
  }
}

@media screen and (min-width: 776px) {
  .modal-container {
    animation-name: modal;
    animation-duration: 1s;
  }
  .modal-title {
    animation: modalTitle 0.6s linear;
  }
  .modal-close {
    animation: modelclose 0.6s linear;
  }
  .modal::-webkit-scrollbar {
    width: 10px;
  }
  .modal::-webkit-scrollbar-thumb {
    background: #000000aa;
    border-radius: 10px;
  }
  .swiper-wrapper {
    padding-bottom: 20px;
  }
  .swiper-pagination {
    bottom: 5px;
  }
  .swiper-pagination-bullet {
    background-color: #000;
  }
}
@keyframes modelclose {
  from {
    transform: scale(0);
  }
  to {
    transform: scale(1);
  }
}
@keyframes modal {
  from {
    transform: translateY(100px);
    opacity: 0.1;
  }
  to {
    transform: translateY(0px);
    opacity: 1;
  }
}
@keyframes modalTitle {
  from {
    transform: translateX(200px);
    opacity: 0.1;
  }
  to {
    transform: translateX(0px);
    opacity: 1;
  }
}
