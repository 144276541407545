.project-section {
  scroll-snap-align: start;
  padding-top: 70px;
}

.section-title {
  margin: 0px auto;
  text-align: center;
  font-size: 26px;
  letter-spacing: -1px;
  text-transform: capitalize;
  display: inline;
  position: relative;
  left: 130px;
}
.section-title:before {
  content: "";
  display: block;
  width: 130px;
  height: 2px;
  background: #191970;
  position: absolute;
  left: -135px;
  top: 50%;
}
.card {
  min-width: 243px;
  height: 293px;
  position: relative;
  transition: 1s;
  margin-bottom: 18px;
  margin-top: 18px;
}
.card-bg {
  width: 100%;
  height: 100%;
  border-radius: 5px;
  display: flex;
  align-items: flex-end;
}
.card-content {
  filter: grayscale(0%);
  position: relative;
  width: 100%;
  height: calc(100% - (100% - 55px));
  background-color: rgb(0, 0, 0, 0.7);
  backdrop-filter: blur(10px);
  overflow: hidden;
  transition: 0.35s linear;
  border-top-left-radius: 5px;
  border-top-right-radius: 5px;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
}
.card-content h1 {
  font-weight: 600;
  text-transform: uppercase;
  margin: 0px;
  font-size: 20px;
  border-bottom: 2px solid #fff;
  color: #fff;
}
.card-content p {
  font-size: 18px;
  color: #fff;
}
.card-content i {
  text-decoration: underline;
  cursor: pointer;
  font-weight: 600;
  color: #fa5555;
}

.card:hover .card-content {
  height: 100%;
}
.card:hover {
  transform: scale(1.02);
}

/* responsive card */
@media screen and (max-width: 776px) {
  .card-content {
    height: 100%;
  }
  .card-bg {
    display: block;
    padding-top: 300px;
  }
  .section-title {
    left: 0;
    display: block;
    text-align: center;
  }
  .section-title:before {
    content: "";
    width: 0px;
  }
}
