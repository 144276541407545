.about-section {
  scroll-snap-align: start;
  background-color: #000;
  color: #fff;
  display: flex;
  flex-direction: column;
}
.about-section-container {
  max-width: 1400px;
  margin: auto;
}
.about-section-title-container {
  margin: auto;
}
.about-section-title {
  background-color: #a60000;
  border-radius: 5px;
  transition: 0.5s;
}
.about-section h1 {
  margin: 1px 0px;
  padding: 20px;
  color: #fff;
  font-size: 1.5rem;
}
.about-section > h1 {
  text-align: center;
  font-size: 26px;
}

.about-content {
  padding: 20px;
  display: flex;
  justify-content: space-between;
}
.about-title {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  margin: 0px 50px;
}
.me {
  width: 400px;
  height: 400px;
  background-color: aliceblue;
  border-radius: 50%;
  background-image: url("../image/me.jpg");
}
.about-icons-container {
  height: 30px;
  margin-right: 20px;
}
.about-icons {
  fill: #fff;
  transition: 0.5s;
  margin-left: 10px;
  margin-right: 10px;
}
.about-icons:hover {
  transform: scale(1.3);
}
.about-icons-container i {
  color: #fff;
  position: relative;
}
@media screen and (max-width: 776px) {
  .about-title {
    margin: 10px 0px;
  }
  .about-content {
    padding: 10px;
    flex-direction: column;
    justify-content: center;
  }
  .me {
    width: 300px;
    height: 300px;
  }
}
