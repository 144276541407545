* {
  box-sizing: border-box;
}
body {
  margin: 0px;
  font-family: "Inter", sans-serif;
}

.bg {
  background-repeat: no-repeat;
  background-position: center;
  background-size: cover;
}

.wrapper {
  height: 100vh;
  scroll-behavior: smooth;
  scroll-snap-type: y proximity;
  overflow-y: scroll;
  width: 100vw;
}

.fixed-nav {
  height: 65px !important;
  transition: 0.4s;
  padding: 15px;
  width: 100%;
  position: fixed;
  top: 0px;
  left: 0;
  background-color: #000000aa;
  z-index: 1;
  box-shadow: 0px 0px 1px #eee;
}
.medium-font {
  font-family: charter, Georgia, Cambria, "Times New Roman", Times, serif;
  font-size: 26px;
  line-height: 1.4;
  text-align: justify;
}
.rev-wave {
  transform: rotate(180deg);
  position: relative;
  top: 10px;
}

@media screen and (min-width: 776px) {
  .wrapper::-webkit-scrollbar {
    width: 10px;
    background-color: #000;
  }
  .wrapper::-webkit-scrollbar-thumb {
    background: #ffffffaa;
    border-radius: 10px;
  }
}
